import React, { useEffect, useState } from "react"
import SectionWrapper from "../../components/section-wrapper/section-wrapper.component"
import Grid from "@mui/material/Grid"
import { graphql, useStaticQuery } from "gatsby"
import NewsCard from "../../components/news-card/news-card.component"
import SocialMedia from "../../components/footer/social-media/social-media.component"
import { SocialContainer } from "./all-news.styles"
import { concat, slice } from "lodash"
import CustomButton from "../../components/custom-button/custom-button.component"
import NewsMonthFilter from "../../components/news-month-filter/news-month-filter.component"
const LIMIT = 8
const AllNews = () => {
  const staticQuery = useStaticQuery(graphql`
    query {
      allWpPost(sort: { fields: date, order: DESC }) {
        edges {
          node {
            featuredImage {
              node {
                altText
                sourceUrl
                title
                localFile {
                  childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH, quality: 100)
                  }
                }
              }
            }
            slug
            uri
            title
            date
            content
          }
        }
      }
    }
  `)

  const news = staticQuery.allWpPost.edges
  const [showMore, setShowMore] = useState(false)
  const [newsToShow, setNewsToShow] = useState([])
  const [index, setIndex] = useState(LIMIT)
  const loadMore = () => {
    const newIndex = index + LIMIT
    const newShowMore = newIndex <= news.length - 1
    const newList = concat(newsToShow, slice(news, index, newIndex))
    setIndex(newIndex)
    setNewsToShow(newList)
    setShowMore(newShowMore)
  }
  const resetProductsToShow = () => {
    setIndex(LIMIT)
    setShowMore(LIMIT <= news.length - 1)
    setNewsToShow(slice(news, 0, LIMIT))
  }
  useEffect(() => {
    if (news) {
      resetProductsToShow()
    }
  }, [news])

  if (!news) return null
  return (
    <SectionWrapper contained bg="light">
      <Grid container spacing={6}>
        <Grid item xs={12} sm={8}>
          {newsToShow &&
            newsToShow.map(item => (
              <NewsCard
                key={item.node?.title}
                image={item.node?.featuredImage?.node}
                title={item.node?.title}
                description={item.node?.content}
                link={`/news/${item.node?.slug}`}
                publicationDate={item.node?.date}
              />
            ))}
          {showMore && (
            <CustomButton
              onClick={loadMore}
              className="darkBorder"
              style={{ display: "block", margin: "auto" }}
            >
              LOAD MORE
            </CustomButton>
          )}
        </Grid>
        <Grid item xs={12} sm>
          <SocialContainer>
            Connect with Us
            <SocialMedia />
          </SocialContainer>
          <NewsMonthFilter news={news} />
        </Grid>
      </Grid>
    </SectionWrapper>
  )
}
export default AllNews
